// Keyboard.js
import React, { useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const KeyboardComponent = ({ onChange }) => {
    const [keyboard, setKeyboard] = useState('default');
  
    const onKeyPress = (button) => {
      onChange(button);
    };

  return (
    <div>
      <Keyboard
         layoutName={keyboard}
         onKeyPress={onKeyPress}
         physicalKeyboardHighlight={true}
         layout={{
            default: [
              "1 2 3 4 5 6 7 8 9 0 {bksp}",
              "Q W E R T Y U I O P",
              "A S D F G H J K L",
              "Z X C V B N M"
            ]
          }}
      />
    </div>
  );
};

export default KeyboardComponent;
