import logo from './logo.svg';
import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
// App.js
import React, { useState, useRef, useEffect } from 'react';
import KeyboardComponent from './Keyboard';

function App() {
  const [licensePlate, setLicensePlate] = useState('');
  const [ticketPrice, setTicketPrice] = useState(null);
  const [blockCode, setBlockCode] = useState('');
  const [paymentInfo, setPaymentInfo] = useState({});
  const [paymentMessage, setPaymentMessage] = useState('');
  const [retryCount, setRetryCount] = useState(0);  // Counter for payment status checks
  const [lastFocused, setLastFocused] = useState(''); // State to track last focused input
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePaymentSuccess = (text) => {
    setPaymentMessage(text);
    setIsModalOpen(true); // Open the modal on payment success
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function trimAndSetState(text, setState) {
    const startStr = "START";
    const endStr = "END";

    if (text.startsWith(startStr) && text.endsWith(endStr)) {
        const trimmedText = text.substring(startStr.length, text.length - endStr.length);
        setState(trimmedText.trim());
    } else {
        setState(text)
    }
}

  const licenseInputRef = useRef(null);
  const blockCodeInputRef = useRef(null);

  useEffect(() => {
    if (ticketPrice !== null) {
      blockCodeInputRef.current.focus(); // Automatically focus on the blockCode input when ticketPrice is set
    }
  }, [ticketPrice]);

  useEffect(() => {
    licenseInputRef.current.focus();
    setLastFocused('licensePlate');
  }, []);

  const handleInputChange = (input) => {
    if (input === "{bksp}") {
      if (lastFocused === 'licensePlate') {
        trimAndSetState(licensePlate.slice(0, -1),setLicensePlate);
      } else if (lastFocused === 'blockCode') {
        setBlockCode(blockCode.slice(0, -1));
      }
    } else {
      if (lastFocused === 'licensePlate') {
        trimAndSetState(licensePlate + input,setLicensePlate);
      } else if (lastFocused === 'blockCode') {
        setBlockCode(blockCode + input);
      }
    }
  };

  const handleFocus = (field) => {
    setLastFocused(field);
  };

  const handleConfirm = () => {
    // Make API call to fetch ticket price
    fetch(`https://app.parkcash.io/api/ExternalParkingTicket/external-parking-ticket-info?ParkingId=e475589b-2b08-48fb-a9ae-000000000000&TicketIdentifier=${licensePlate}`)
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to fetch ticket price');
      }
    })
    .then(data => {
      if (data.isSuccess && data.result && data.result.price !== undefined) {
        const ticketPrice = data.result.price;
        setTicketPrice(ticketPrice);
        setBlockCode('');
      } else if (!data.isSuccess && data.error && data.error.code === 90 && data.result === null) {
        handlePaymentSuccess('Nie znaleziono biletu. Zweryfikuj wprowadzone dane.');
      } else {
        throw new Error('Invalid response format');
      }
    })
    .catch(error => {
      console.error('Error fetching ticket price:', error);
      // Handle error, e.g., display an error message
    });
  };

  const handleBlockCodeChange = (event) => {
    setBlockCode(event.target.value);
  };
  const handleLicPlateChange = (event) => {
    trimAndSetState(event.target.value,setLicensePlate);
  };
  function Modal({ isOpen, onClose, children }) {
    if (!isOpen) return null;
  
    return (
      <div style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <div style={{ background: 'white', padding: 20, borderRadius: 8, width: '80%', maxWidth: 400 }}>
          {children}
          <button onClick={onClose} style={{ marginTop: 10 }}>Zamknij</button>
        </div>
      </div>
    );
  }

  const handlePay = () => {
    handlePaymentSuccess(`Potwierdź płatność w aplikacji bankowej...`);
    const payload = {
      parkingId: "e475589b-2b08-48fb-a9ae-000000000000",
      ticketIdentifier: licensePlate,
      blikCode: blockCode
    };

    fetch('https://app.parkcash.io/api/ExternalParkingTicket/start-external-parking-ticket-payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      console.log('Payment initiated:', data);
      setPaymentInfo(data);
      setRetryCount(0); // Reset the retry count every time payment is attempted
      checkPaymentStatus(data.result.id);
    })
    .catch(error => console.error('Error during payment processing:', error));
  };

  const checkPaymentStatus = (externalTicketId) => {
    if (retryCount < 10) {
      fetch(`https://app.parkcash.io/api/ExternalParkingTicket/external-parking-ticket-status?ExternalTicketId=${externalTicketId}`)
      .then(response => response.json())
      .then(data => {
        if (data.result && data.result.status === 1) {
          handlePaymentSuccess('Płatność zakończona. Szerokiej drogi!');
          setLicensePlate('');
          setBlockCode('');
          setTicketPrice(null);
        } else {
          handlePaymentSuccess(`Potwierdź płatność w aplikacji bankowej...`);
          setRetryCount(retryCount + 1);
          setTimeout(() => checkPaymentStatus(externalTicketId), 1000); // Retry after 1 second          
        }
      })
      .catch(error => {
        console.error('Error fetching payment status:', error);
        handlePaymentSuccess('Error fetching payment status');
      });
    } else {
      setPaymentMessage('Maximum retries reached. Payment status check failed.');
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className='div50'>
        <p className="warn">TEST MODUŁU</p>
        <p className="header">Wprowadź numer rejestracyjny pojazdu</p>
      
        <input 
        className="input" 
        type="text" 
        value={licensePlate} 
        onChange={handleLicPlateChange} 
        ref={licenseInputRef}
        onFocus={() => handleFocus('licensePlate')}/>
        <KeyboardComponent onChange={handleInputChange} />
        <button className="button"  onClick={handleConfirm}>Sprawdź kwotę</button>
        </div>
        {ticketPrice !== null && (
          <div className='div50'>
            <p>Koszt: {ticketPrice} PLN</p>
            <input 
              className="input"
              type="text"
              value={blockCode}
              placeholder="Wprowadź Kod BLIK"
              onChange={handleBlockCodeChange}
              onFocus={() => handleFocus('blockCode')}
              ref={blockCodeInputRef}
            />
            <button className="button" onClick={handlePay}>Zapłać</button>
            <p>{paymentMessage}</p>
          </div>
        )}
      </header>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <p>{paymentMessage}</p>
      </Modal>
    </div>
  );
}

export default App;
